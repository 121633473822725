import React, { useState } from 'react';
import './navbar.css'; // Import your CSS file for styling
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleMobileToggle = () => {
    setIsMobile(!isMobile);
  };

  const closeMobileMenu = () => {
    setIsMobile(false);
  };

  return (
    <div className='main-cont nav-bg'>
    <nav className="navbar">
      <ul className={`nav-items ${isMobile ? 'mobile' : ''}`}>
        <li><Link to="./" onClick={closeMobileMenu}>Home</Link></li>
        <li className={`dropdown ${isMobile ? 'mobile-dropdown' : ''}`}>
          <Link to="./about" onClick={closeMobileMenu}>About BTA</Link>
        </li>
        <li className={`dropdown ${isMobile ? 'mobile-dropdown' : ''}`}>
          <Link to="./events" onClick={closeMobileMenu}>Events</Link>
        </li>
        <li className={`dropdown ${isMobile ? 'mobile-dropdown' : ''}`}>
          <Link to="./community" onClick={closeMobileMenu}>Community Help</Link>
        </li>
        <li className={`dropdown ${isMobile ? 'mobile-dropdown' : ''}`}>
          <Link to="./help" onClick={closeMobileMenu}>Ask for Help</Link>
        </li>
        <li className={`dropdown ${isMobile ? 'mobile-dropdown' : ''}`}>
          <Link to="./gallery" onClick={closeMobileMenu}>Gallery</Link>
        </li>
        <li><Link to="./contact" onClick={closeMobileMenu}>Contact</Link></li>
      </ul>
      <div className="mobile-menu" onClick={handleMobileToggle}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </nav>
    </div>
    
  );
};

export default Navbar;

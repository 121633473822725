import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Gallery from "./components/gallery/gallery";
import ContactUs from './components/contact/contact';
import About from './components/about/about';
import Home from './components/Home/Home';
import SubHome from './components/Home/SubHome/SubHome';
import EventsList from './components/Banner/events/eventscaurosel/eventscaurosel';
import Community from './components/Community/Community';
import Help from './components/Help/Help';

function App() {
  return (
    <Router>
    <Routes>
    <Route path="/" element={<Home />} >
    <Route index element={<SubHome />} />
    <Route path="about" element={<About />} />
    <Route path="events" element={<EventsList />} />
    <Route path="gallery" element={<Gallery />} />
    <Route path="contact" element={<ContactUs />} />
    <Route path="community" element={<Community />} />
    <Route path="help" element={<Help />} />
    </Route>
    </Routes>
    </Router>
  );
}

export default App;

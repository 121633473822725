import React from 'react'
import Development from '../images/development.jpg';
import "./Community.css";


function Community() {
  return (
    
    <div className='main-cont'><img src={Development} className='develop-image' /></div>
  )
}

export default Community
import React from 'react';
import './eventscaurosel.css'; // You can style your component in a separate CSS file
import Development from '../../../images/development.jpg';

const EventList = () => {
  // const events = [
  //   { id: 1, imageUrl: 'https://via.placeholder.com/260x173/FF5733/FFFFFF?text=image+1', heading: 'Event 1' },
  //   { id: 2, imageUrl: 'https://via.placeholder.com/260x174/33FF57/FFFFFF?text=Image+2', heading: 'Event 2' },
  //   { id: 3, imageUrl: 'https://via.placeholder.com/260x175/5733FF/FFFFFF?text=Image+3', heading: 'Event 3' },
  //   { id: 4, imageUrl: 'https://via.placeholder.com/260x176/FF33C1/FFFFFF?text=Image+4', heading: 'Event 4' },
  //   { id: 5, imageUrl: 'https://via.placeholder.com/260x177/33C1FF/FFFFFF?text=Image+5', heading: 'Event 5' },
  //   { id: 6, imageUrl: 'https://via.placeholder.com/260x178/C1FF33/FFFFFF?text=Image+6', heading: 'Event 6' },
  //   // Add more events as needed
  // ];

  return (
    <div className="event-list-container">
      {/* {events.map((event) => (
        <div key={event.id} className="event-card">
          <img src={event.imageUrl} alt={event.heading} style={{ maxWidth: '100%' }} />
          <h3>{event.heading}</h3>
          <button>View Details</button>
        </div>
      ))} */}
      <div className='main-cont'><img src={Development} className='develop-image' /></div>
    </div>
  );
};

export default EventList;

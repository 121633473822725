import React from 'react';
import './about.css';
import TempleImage from "../images/temple.jpg";

const About = () => {
    return (
        <div className="main-cont">
            <div className="about">
                <div className="about-inside-cont">
                    <div className="about-text-cont">
                        <h1>About BTA</h1>
                        <div className='about-img-cont deskHide mobShow'>
                            <img src={TempleImage} />
                        </div>
                        <p>BTA is a non-profit organization which aims to perpetuate the Telugu Samskruti (Culture), 
                            Sahityam (Literature) and Sampradayam (Tradition) among the Telugu Sangham (telugu speaking people in UK). 
                            BTA is committed to raise the awareness of the Telugu culture among the denizens of UK by organizing 
                            unique cultural and literary events that depict the rich cultural heritage traditions and arts of 
                            Andhra Pradesh and Telangana. The purpose of the organization is to serve the community through 
                            cultural and charity work. One of the key objectives of BTA is to educate the kids born and 
                            raised in UK and the youth migrated to UK, about the rich Telugu cultural heritage in the fields 
                            of Art, music, dance, language, poetry, traditions and family values. </p>
                    </div>
                    <div className='about-img-cont mobHide deskShow'>
                        <img src={TempleImage} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;

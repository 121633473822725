import React from 'react'
import Development from '../images/development.jpg';
import "./Help.css";

function Help() {
  return (
    <div className='main-cont'><img src={Development} className='develop-image' /></div>
  )
}

export default Help
import React from 'react'
import Banner from '../../Banner/banner';
import Clients from '../../clients/clients';
import Gallery from '../../gallery/gallery';
import LatestNews from '../../latestNews/latestNews';

export default function SubHome() {
  return (
    <>
    <LatestNews />
    <Banner />
    <Gallery />
    </>
  )
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

import './footer.css'; // Import your custom CSS file for styling

const Footer = () => {
  return (
    <div>
    <div className='main-cont'>
    <footer className="footer-container">
      <div className="footer-section contact-details">
        <h3>Contact Us</h3>
        <p>Email: btabournemouthorg@gmail.com</p>
      </div>

      <div className="footer-section services">
        <h3>Useful Links</h3>
        <p>BTA Committees</p>
        <p>Programs And Events</p>
        <p>Youth Programs</p>
        <p>Charity/Projects</p>
      </div>

      <div className="footer-section social-icons">
        <h3>Follow Us</h3>
        <ul>
          <li><a href="https://www.facebook.com/share/R4AvMUgAiRMLKTKR/?mibextid=K35XfP" target="_blank"><FontAwesomeIcon icon={faFacebook} /></a></li>
          <li><a href="#" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a></li>
          <li><a href="#" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></li>
          <li><a href="#" target="_blank"><FontAwesomeIcon icon={faYoutube} /></a></li>
        </ul>
      </div>
    </footer>
    </div>
    <div className="main-cont copy-right-bg">
      <div className="copy-right">
        <p>© 2011-2024 Bournemouth Telugu Association. All Rights Reserved.</p>
        <p className='developer-name'>Developed by: Krishna kanth</p>
      </div>
    </div>
    </div>
  );
};

export default Footer;

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './gallery.css';

const Gallery = () => {
  // Dummy image list
  const imageList = [
    'https://ik.imagekit.io/sriHari143/BTA/image1_qiQv-Or0_F.jpeg?updatedAt=1712981814029',
    'https://ik.imagekit.io/sriHari143/BTA/image7_cFfpgUG-6W.jpeg?updatedAt=1712981815473',
    'https://ik.imagekit.io/sriHari143/BTA/image8_XuNPhdvM5.jpeg?updatedAt=1712981815112',
    'https://ik.imagekit.io/sriHari143/BTA/image6_XdokuhEdF.jpeg?updatedAt=1712981815037',
    'https://ik.imagekit.io/sriHari143/BTA/image5_rojYtIhZP.jpeg?updatedAt=1712981814752',
    'https://ik.imagekit.io/sriHari143/BTA/image4_6foi3q_YQl.jpeg?updatedAt=1712981814586',
    'https://ik.imagekit.io/sriHari143/BTA/image3_1TNTdhxsQE.jpeg?updatedAt=1712981814462',
    'https://ik.imagekit.io/sriHari143/BTA/image2_kjFThhDguj.jpeg?updatedAt=1712981814429',
    'https://ik.imagekit.io/sriHari143/BTA/image9_A_w6iU9542.jpeg?updatedAt=1712981817272',
    'https://ik.imagekit.io/sriHari143/BTA/image30_JkPOVfhnOA.jpeg?updatedAt=1712981813615',
    'https://ik.imagekit.io/sriHari143/BTA/image29_AMViHrsox.jpeg?updatedAt=1712981811857',
    'https://ik.imagekit.io/sriHari143/BTA/image25_VeZo9nVr8r.jpeg?updatedAt=1712981811663',
    'https://ik.imagekit.io/sriHari143/BTA/image28_2QktCs2X_t.jpeg?updatedAt=1712981811474',
    'https://ik.imagekit.io/sriHari143/BTA/image27_mIR8lp-I9c.jpeg?updatedAt=1712981811242',
    'https://ik.imagekit.io/sriHari143/BTA/image23_V_4XSg8YAJ.jpeg?updatedAt=1712981811237',
    'https://ik.imagekit.io/sriHari143/BTA/image26_j546RgB234.jpeg?updatedAt=1712981811148',
    'https://ik.imagekit.io/sriHari143/BTA/image22_qvImL9KD6f.jpeg?updatedAt=1712981811184',
    'https://ik.imagekit.io/sriHari143/BTA/image24_npU8i2CNcE.jpeg?updatedAt=1712981810991',
    'https://ik.imagekit.io/sriHari143/BTA/image21_SFdHWJcGW5.jpeg?updatedAt=1712981810634',
    'https://ik.imagekit.io/sriHari143/BTA/image20_3OSHEfnU1.jpeg?updatedAt=1712981810465',
    'https://ik.imagekit.io/sriHari143/BTA/image13_0fvdBCQg7k.jpeg?updatedAt=1712981807586',
    'https://ik.imagekit.io/sriHari143/BTA/image12_IXK2ljeEvp.jpeg?updatedAt=1712981807591',
    'https://ik.imagekit.io/sriHari143/BTA/image18_5qJVEbtUp6.jpeg?updatedAt=1712981807577',
    'https://ik.imagekit.io/sriHari143/BTA/image19_q8KgBVZTuP.jpeg?updatedAt=1712981807475',
    'https://ik.imagekit.io/sriHari143/BTA/image16__9sNtSBuWs.jpeg?updatedAt=1712981807470',
    'https://ik.imagekit.io/sriHari143/BTA/image14_RF3Z3YFMYQ.jpeg?updatedAt=1712981807466',
    'https://ik.imagekit.io/sriHari143/BTA/image11_-JjfOzz3rO.jpeg?updatedAt=1712981807389',
    'https://ik.imagekit.io/sriHari143/BTA/image15_c7i5eYEKJM.jpeg?updatedAt=1712981807355',
    'https://ik.imagekit.io/sriHari143/BTA/image17_67Ei14cqa5.jpeg?updatedAt=1712981807351',
    'https://ik.imagekit.io/sriHari143/BTA/image10_2Ncp940Cg.jpeg?updatedAt=1712981806818',
  ];

  // Slick settings
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className='main-cont'>
    <div className='gallery'>
      <h2>Image Gallery</h2>
      <Slider {...settings}>
        {imageList.map((imageUrl, index) => (
          <div key={index} className='img-pad'>
            <img src={imageUrl} alt={`Image ${index}`} style={{ width: '100%', height: 'auto' }} />
          </div>
        ))}
      </Slider>
    </div>
    </div>
  );
};

export default Gallery;

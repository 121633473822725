import React, { useState, useEffect } from 'react';
import './caurosel.css'; // Import the CSS file

const images = [
  'https://ik.imagekit.io/sriHari143/BTA/image3_1TNTdhxsQE.jpeg?updatedAt=1712981814462',
  'https://ik.imagekit.io/sriHari143/BTA/image4_6foi3q_YQl.jpeg?updatedAt=1712981814586',
  'https://ik.imagekit.io/sriHari143/BTA/image5_rojYtIhZP.jpeg?updatedAt=1712981814752',
  'https://ik.imagekit.io/sriHari143/BTA/image29_AMViHrsox.jpeg?updatedAt=1712981811857',
  'https://ik.imagekit.io/sriHari143/BTA/image21_SFdHWJcGW5.jpeg?updatedAt=1712981810634',
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000); // Auto slide every 5 seconds

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="carousel">
      <div className="arrow left" onClick={prevSlide}>
        &#9665;
      </div>
      <div className="image-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={index === currentIndex ? 'active' : 'hide'}
          />
        ))}
      </div>
      <div className="arrow right" onClick={nextSlide}>
        &#9655;
      </div>
      <div className="dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={index === currentIndex ? 'dot active' : 'dot'}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;

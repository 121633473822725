import React, { useState, useEffect } from 'react';
import './latestNews.css'; // Import CSS file for styling

const DummyNewsList = () => {
  const newsItems = [
    "UGADI celebrations 13th april 11:00AM - 5:00PM",
    "BTA SPORTS FEST June end-2024 10:00AM - 5:00PM",
    "UGADI celebrations 13th april 11:00AM - 5:00PM",
    "BTA SPORTS FEST June end-2024 10:00AM - 5:00PM",
    "UGADI celebrations 13th april 11:00AM - 5:00PM"
  ];

  return (
    <div className='main-cont'>
    <div className="dummy-news-list">
      <h2>Latest News</h2>
      <NewsScroll newsItems={newsItems} />
    </div>
    </div>
  );
};

const NewsScroll = ({ newsItems }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % newsItems.length);
    }, 3000); // Change the interval duration as needed (3000ms = 3 seconds)

    return () => clearInterval(interval);
  }, [newsItems.length]);

  return (
    <div className="news-scroll">
      {newsItems.map((item, i) => (
        <div key={i} className={i === index ? 'active' : 'inactive'}>
          {item}
        </div>
      ))}
    </div>
  );
};

export default DummyNewsList;

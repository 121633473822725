import React from 'react'
import { Outlet } from 'react-router-dom';
import './Home.css';
import Navbar from '../Navbar/navbar';
import Footer from '../footer/footer';
import Header from '../Header/header';

export default function Home() {
  return (
    <>
    <div className='main-bg'>
    <Header />
    <Navbar />
    <Outlet />
    <Footer />
    </div>
    </>
  )
}

import React from 'react';
import './banner.css'; 
import Carousel from './caurosel/caurosel';
import Events from './events/events';
import Video from '../images/video1.mp4';


const Banner = () => {
  return (
    <div className='main-cont'>
      <div className="banner">
        <div className='video-main-sec'>
          <video controls className='video-sec' poster='https://ik.imagekit.io/sriHari143/BTA/image2_kjFThhDguj.jpeg?updatedAt=1712981814429'>
          <source src={Video} type="video/mp4" />
          </video>
        </div>
        <Carousel />
        <Events />
      </div>
    </div>
  );
};

export default Banner;

import React from 'react';
import "./header.css";
import Logo from "../images/logo1.png"

const Header = () => {
    return (
        <div className="main-cont head-bg">
            <div className="header">
                <div>
                    <img src={Logo} className='logo' />
                </div>
                <div className='head-cont'>
                    <h3 className='heading'>Bournemouth Telugu Association</h3>
                    <h3 className='quotes'>Passion For Our Culture, Compassion For Our People</h3>
                    <h3 className='telugu'>బోర్న్‌మౌత్ తెలుగు సంఘం</h3>
                </div>
            </div>
        </div>
    );
}

export default Header;

import React from 'react';
import './events.css';
import Image1 from "../../images/Ugadi.jpeg"

const Events = () => {
    return (
        <div className='main-cont'>
        <div className='events'>
            <h1 className='events-heading'>Upcoming Events...</h1>
            <img src="https://ik.imagekit.io/sriHari143/BTA/upcoming_u04qOO2BFL.jpeg?updatedAt=1712981813601" />
        </div>
        </div>
    );
}

export default Events;
